import React, { useState, useEffect } from 'react';

import { API, PubSub, Auth } from 'aws-amplify';

import { withAuthenticator, AmplifySignOut } from '@aws-amplify/ui-react';
import Iot from 'aws-sdk/clients/iot';
import IotData from 'aws-sdk/clients/iotdata';

function Dashboard() {
  const [message, setMessage] = useState();
  const [led, setLed] = useState();
  const ledToggle = () => {
    setLed("...");
    updateThingShadow("ESP-32", {"state" : {"desired": {"led": led === "ON" ? "OFF" : "ON"} } });
  };

  useEffect(() => {
    getAPIData();

    async function fetchShadow() {
      const shadow = await getThingShadow("ESP-32");
      if(shadow.state.reported.led) {
        setLed(shadow.state.reported.led);
      }
    }
    fetchShadow();

    const subscription = PubSub.subscribe('$aws/things/ESP-32/shadow/update/accepted').subscribe({
      next: data => {
        console.log("[", data.value[Object.getOwnPropertySymbols(data.value)[0]], "] ", JSON.stringify(data.value));
        if(data.value.state.reported) {
          setLed(data.value.state.reported.led);
        }
      },
      error: error => console.error(error),
    });

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  const getAPIData = async () => { 
    const apiName = 'testapi';
    const path = '/test';
    const data = await API.get(apiName, path);
    setMessage(data.success);
  }

  const listThings = async () => {
    let credentials = await Auth.currentCredentials();
    const iot = new Iot({
      region: 'eu-central-1',
      credentials: Auth.essentialCredentials(credentials)
    });
    const data = await iot.listThings().promise();
    console.log("Things: " + data.things.map((thing) => thing.thingName).join(","));
  }

  const getThingShadow = async (thingName) => {
    let credentials = await Auth.currentCredentials();
    const iotdata = new IotData({
      endpoint: 'a1l0235awtdddf-ats.iot.eu-central-1.amazonaws.com',
      region: 'eu-central-1',
      credentials: Auth.essentialCredentials(credentials)
    });
    const data = await iotdata.getThingShadow({
      thingName: thingName
    }).promise();
    return JSON.parse(data.payload);
  }

  const updateThingShadow = async (thingName, shadow) => {
    let credentials = await Auth.currentCredentials();
    const iotdata = new IotData({
      endpoint: 'a1l0235awtdddf-ats.iot.eu-central-1.amazonaws.com',
      region: 'eu-central-1',
      credentials: Auth.essentialCredentials(credentials)
    });
    const data = await iotdata.updateThingShadow({
      thingName: thingName,
      payload: JSON.stringify(shadow)
    }).promise();
    return JSON.parse(data.payload);
  }

  return (
    <div className="App">
      <header className="App-header">
        <img src="bulb_on.png" className="App-logo" alt="logo" />
        <p>
          LED: {led}
        </p>
        <p>
          <button onClick={ledToggle}>Toggle</button>
        </p>
        <p>
          <button onClick={listThings}>List Things</button>
        </p>
        <AmplifySignOut />
      </header>
    </div>
  );
}

export default withAuthenticator(Dashboard);