import React, { useEffect, useState } from 'react';
import './App.css';
import Dashboard from './Dashboard';

import Amplify, { Auth, PubSub } from 'aws-amplify';
import awsExports from "./aws-exports";
import { AWSIoTProvider } from '@aws-amplify/pubsub/lib/Providers';
import IotData from 'aws-sdk/clients/iotdata';



Amplify.configure(awsExports);
Amplify.addPluggable(new AWSIoTProvider({
  aws_pubsub_region: 'eu-central-1',
  aws_pubsub_endpoint: 'wss://a1l0235awtdddf-ats.iot.eu-central-1.amazonaws.com/mqtt',
}));

const App = () => {
  const [authenticated, setAuthenticated] = useState();

  useEffect(() => {
    const checkAuth = async () => {
      const userCredentials = await Auth.currentUserCredentials();
      setAuthenticated(userCredentials.authenticated);
    }
    checkAuth();
  }, []);
  return (
    <div className="App">
      {!authenticated && <Status />}
      <Dashboard />
    </div>
  );
}

const Status = () => {
  const [status, setStatus] = useState();

  useEffect(() => {
    async function fetchShadow() {
      try {
        const shadow = await getThingShadow("ESP-32");
        if(shadow.state.reported.led) {
          setStatus("ESP-32: led " + shadow.state.reported.led);
        } else {
          setStatus("ESP-32: " + JSON.stringify(shadow));
        }
      } catch (error) {
        setStatus("ERROR: " + error.code);
      }
    }
    fetchShadow();

    const subscription = PubSub.subscribe('$aws/things/ESP-32/shadow/update/accepted').subscribe({
      next: data => {
        if(data.value.state.reported) {
          setStatus("ESP-32: led " + data.value.state.reported.led);
        }
      },
      error: error => console.error(error),
    });
  
    return () => {
      subscription.unsubscribe();
    };

  }, []);

  const getThingShadow = async (thingName) => {
    let credentials = await Auth.currentCredentials();
    const iotdata = new IotData({
      endpoint: 'a1l0235awtdddf-ats.iot.eu-central-1.amazonaws.com',
      region: 'eu-central-1',
      credentials: Auth.essentialCredentials(credentials)
    });
    const data = await iotdata.getThingShadow({
      thingName: thingName
    }).promise();
    return JSON.parse(data.payload);
  }

  return (
    <p>{status}</p>
  );

};

export default App;
